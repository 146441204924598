<template>
    <div>
        <div class="page-title">消息推送设置</div>

        <a-card title="已绑定微信" class="mb-4">

            <a-spin :spinning="loadingMasters"></a-spin>

            <template v-if="!loadingMasters">

                <a-empty v-if="!masters.length" description="暂无绑定用户"/>

                <div v-else class="master-list">

                    <template v-for="(item,ik) in masters">

                        <div :key="ik" class="master-item">


                            <div>
                                <img class="mr-2 master-avatar" :src="item.avatar" alt="">

                                {{ item.mark_name || item.name }}

                            </div>

                            <template v-if="isOwner || item.member_id === memberId ">

                                <role-owner>
                                    <button @click="unbind(item)" class="btn btn-white small">解除绑定</button>
                                </role-owner>

                            </template>

                        </div>


                    </template>

                </div>

            </template>


        </a-card>

        <a-card title="绑定微信" class="mb-4">
            <a-alert type="success" message="绑定微信后，可以收到待办事项等推送，方便我们更好的为您服务"/>
            <p class="line-msg">管理员关注『颜创时代』服务号（关注后才可以收到模板消息）</p>

            <div class="line-msg">
                <div style="width: 150px">
                    <img width="150px" src="https://static.ycsd.work/collocation-helper/static/images/yc-qrcode.png"
                         alt="">
                    <div class="text-center"><span>(微信扫码关注)</span></div>
                </div>
            </div>

        </a-card>

        <a-card class="mb-4">
            <div slot="title">
                <span>推送设置</span>
                <small>(可设置多人提醒)</small>
            </div>
            <div class="master-list">

                <template v-for="(item,ik) in rows">
                    <div class="master-item" :key="ik">

                        <div style="width: 300px">{{ item.title }}</div>

                        <div style="flex: 1">
                            <a-select class="full-with" mode="tags" placeholder="点击选择提醒对象"
                                      v-model="params[item.key]">

                                <template v-for="(master,mk) in masters">

                                    <a-select-option :value="`${master.member_id}`" :key="mk">
                                        <img class="mr-2 master-avatar small" :src="master.avatar" alt="">
                                        <span>{{ master.mark_name || master.name }}</span>
                                    </a-select-option>

                                </template>

                            </a-select>
                        </div>

                    </div>

                </template>

                <div class="master-item">
                    <button class="btn" @click="savePushInfo">保存</button>
                </div>

            </div>

        </a-card>


    </div>
</template>

<script>
import api from "@/repo/api";
import auth from "@/auth";
import RoleOwner from "@/components/roles/RoleOwner.vue";

export default {
    name: "Push",
    components: {RoleOwner},
    props: {
        app: Object
    },
    data() {
        return {
            loadingMasters: true,
            masters: [],
            isOwner: false,
            memberId: 0,
            params: {},
            rows: [
                {
                    title: "AI检测付款消息推送提醒",
                    key: "detect_paid",
                },
                {
                    title: "风格诊断报告付款推送提醒",
                    key: "paper_paid",
                },
                {
                    title: "风格实体报告开始印刷提醒",
                    key: "paper_print",
                },
                {
                    title: "风格实体报告发货提醒",
                    key: "paper_post",
                },
                {
                    title: "课程/活动/服务报名提醒推送",
                    key: "course_apply"
                },
                // {
                //     title: "用户生活照及档案信息完善度达70%提醒",
                //     key: "member_image_finish"
                // }
            ],
        }
    },
    mounted() {
        this.getMasters()
        this.getPushInfo()

        this.memberId = auth.userInfo().id
        this.isOwner = this.memberId === this.app.owner_id;
    },
    methods: {
        savePushInfo() {
            this.$loading.show()
            console.log(this.params);

            api.post('/ast-notify/save-push-info', {params: this.params}, res => {

                this.$loading.hide()

                this.$message.data(res)

                if (res.code === 0) this.getPushInfo()

            })
        },
        getPushInfo() {
            api.get('/ast-notify/push-info', {params: this.params}, res => {

                if (res.code === 0) {
                    this.params = res.data || {}

                    if (this.params instanceof Array) this.params = {}

                    console.log(this.params);
                }

            })
        },
        unbind(item) {
            this.$confirm({
                title: "确认要解除绑定吗？",
                content: "解除绑定之后，该用户将接收不到任何推送信息.",
                onOk: () => {
                    this.$loading.show()

                    api.post('/ast-notify/unbind-mp', {member_id: item.member_id}, res => {

                        this.$loading.hide()

                        this.$message.data(res)

                        if (res.code === 0) this.getMasters()

                    })


                }
            })
        },
        getMasters() {

            this.loadingMasters = true

            api.get('/ast-notify/followed-masters', (res) => {

                this.loadingMasters = false

                this.masters = res.data

            })
        }
    }
}
</script>

<style scoped lang="less">

.line-msg {
    margin-top: 12px;
}

.master-item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 1px dashed #ddd;

    &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom-width: 0;
    }
}

.master-avatar {
    width: 40px;
    border-radius: 50%;

    &.small {
        width: 20px;
    }
}

</style>