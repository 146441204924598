<template>
    <span>
        <slot v-if="can" />
    </span>
</template>

<script>
export default {
    name: "RoleOwner",
    data() {
        return {}
    },
    mounted() {

    },
    computed:{
        can(){
            return this.$app.roles.indexOf('owner') >= 0
        }
    }
}
</script>

<style scoped lang="less">

</style>